/**
*    Container
**/
#onetrust-consent-sdk {
  --color-black: #151515;
  --color-blue: #041c2c;
  --color-dark-grey: #676767;
  --color-white: #ffffff;

  --font-size-normal: 0.75rem;

  --font-regular: 'nimbus-sans-d', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  --font-extd: 'nimbus-sans-extd-d', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

/**
*     Floating button (after saving settings)
**/
#ot-sdk-btn-floating {
  display: none !important;
}

/**
*     Buttons
**/
#onetrust-accept-btn-handler,
#onetrust-pc-btn-handler,
.onetrust-close-btn-handler,
#onetrust-reject-all-handler,
#accept-recommended-btn-handler {
  margin: 0 !important;
  min-width: unset !important;
  max-width: unset !important;
  border-radius: 0 !important;
  padding: 0.8rem 2rem !important;
  border: 1px solid var(--color-blue) !important;
  text-transform: uppercase !important;
  font-family: var(--font-extd) !important;
  line-height: 1.1 !important;
  background: var(--color-blue) !important;
  font-weight: normal !important;
  width: unset !important;
  outline-offset: 2px;
  font-size: var(--font-size-normal) !important;
  letter-spacing: unset !important;

  &#onetrust-pc-btn-handler,
  &#onetrust-reject-all-handler {
    background: var(--color-white) !important;
  }

  &:hover,
  &:focus {
    opacity: unset !important;
  }
}

/**
*     Banner
**/
#onetrust-banner-sdk {
  --spacing: 1rem;
  --outer-spacing: 5rem;

  width: unset !important;
  max-width: unset !important;
  right: 0 !important;
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
  min-height: unset !important;
  font-family: var(--font-regular);
}

.ot-sdk-container {
  padding: var(--spacing) !important;
}

.ot-sdk-row {
  display: flex !important;
  flex-direction: column;
  gap: var(--spacing);
}

.ot-sdk-row .ot-sdk-eight.ot-sdk-columns {
  flex: 1;
  width: unset !important;
  display: flex !important;
  align-items: center;
}

#onetrust-banner-sdk .ot-sdk-row::after {
  display: none !important;
}

/**
*     Banner text
**/
#onetrust-banner-sdk .policy {
  margin: unset !important;
  padding-bottom: unset !important;
}

#onetrust-policy-text {
  text-align: center !important;
  font-size: var(--font-size-normal) !important;
  line-height: 1.3 !important;
  color: var(--color-black) !important;
}

/**
*     Banner buttons
**/
#onetrust-button-group-parent {
  width: unset !important;
  margin-left: unset !important;
  float: unset !important;
}

#onetrust-button-group {
  display: flex !important;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-right: unset !important;
  flex-direction: column;
  font-family: var(--font-extd);
  line-height: 1.1 !important;
}

#onetrust-accept-btn-handler,
#onetrust-pc-btn-handler,
#onetrust-reject-all-handler {
  width: 100% !important;
  height: 100% !important;
}

.banner-close-btn-container {
  display: none !important;
}

.accept-btn-container,
.pc-btn-container {
  flex: 1;
}

#onetrust-accept-btn-handler,
#onetrust-pc-btn-handler,
#onetrust-reject-all-handler {
  font-weight: normal !important;

  &:hover,
  &:focus {
    opacity: unset !important;
  }
}

#onetrust-accept-btn-handler {
  background: var(--color-blue) !important;
}

#onetrust-pc-btn-handler,
#onetrust-reject-all-handler {
  color: var(--color-blue) !important;
}

#onetrust-banner-sdk #onetrust-policy {
  margin: 0 !important;
}

#onetrust-close-btn-container {
  display: none !important;
}

@media (min-width: 801px) {
  .ot-sdk-container {
    padding: var(--spacing) var(--spacing) var(--spacing) calc(var(--spacing) * 2) !important;
  }

  #onetrust-banner-sdk {
    right: var(--outer-spacing) !important;
    left: var(--outer-spacing) !important;
  }

  .ot-sdk-row {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .ot-sdk-row .ot-sdk-eight.ot-sdk-columns {
    min-width: 20rem !important;
  }
  #onetrust-policy {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  #onetrust-policy-text {
    text-align: left !important;
    margin-bottom: 0 !important;
  }

  .accept-btn-container,
  .pc-btn-container {
    flex: unset;
  }
}

/**
*     Popup
**/
#onetrust-pc-sdk {
  --outer-spacing: 2rem;
  --inner-spacing: 2.5rem;

  --desktop-max-width: min(calc(100% - calc(var(--outer-spacing) * 2)), 50rem);
  --desktop-max-height: min(calc(100% - calc(var(--outer-spacing) * 2)), 56.25rem);

  width: min(100%, 50rem) !important;
  height: 100% !important;
  max-height: 100% !important;
  display: flex;
  flex-direction: column;
  border-radius: unset !important;
  box-shadow: unset !important;
  min-width: unset !important;
  max-width: unset !important;
}

.ot-pc-header {
  border-bottom: unset !important;
  display: flex !important;
  justify-content: flex-end;
  padding: var(--inner-spacing) !important;
  height: unset !important;
}

#close-pc-btn-handler {
  position: relative !important;
  top: unset !important;
  right: unset !important;
  margin: 0 !important;

  &:hover,
  &:focus {
    opacity: unset !important;
  }
}

#onetrust-pc-sdk .ot-close-icon {
  background-size: 8px !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.ot-pc-footer-logo {
  display: none !important;
}

.ot-pc-logo {
  display: none !important;
}

/**
*     Popup text
**/
#ot-pc-content {
  position: relative !important;
  bottom: unset !important;
  top: unset !important;
  flex: 1;
  padding: 0 var(--inner-spacing) !important;
  width: unset !important;
  margin: unset !important;
}

#ot-pc-content h2,
#ot-pc-content h3 {
  font-family: var(--font-extd) !important;
  text-transform: uppercase !important;
  color: var(--color-black) !important;
  margin-bottom: 0.875rem !important;
  font-size: 0.875rem !important;
  line-height: 1.3 !important;
}

#ot-pc-desc {
  font-size: var(--font-size-normal) !important;
  line-height: 1.3 !important;
  color: var(--color-dark-grey) !important;
  font-family: var(--font-regular) !important;
  margin-bottom: 2rem !important;
}

.ot-cat-grp {
  display: flex !important;
  flex-wrap: wrap !important;
}

#ot-category-title {
  margin-top: 1rem !important;
}

.privacy-notice-link {
  color: black !important;
}

/**
*     Popup buttons
**/
.ot-pc-footer {
  position: relative !important;
  border-top: unset !important;
}

.ot-pc-refuse-all-handler {
  display: none !important;
}

.ot-btn-container {
  margin: unset !important;
  display: flex;
  justify-content: center;
  width: unset !important;
  padding: var(--inner-spacing) !important;
}

.onetrust-close-btn-handler {
  width: 100% !important;
}

@media (min-width: 601px) {
  #onetrust-pc-sdk {
    height: unset !important;
    width: 100% !important;
    max-width: var(--desktop-max-width) !important;
    max-height: var(--desktop-max-height) !important;
    left: var(--outer-spacing) !important;
    right: var(--outer-spacing) !important;
    top: var(--outer-spacing) !important;
    bottom: var(--outer-spacing) !important;
  }

  #onetrust-button-group {
    display: grid !important;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 0 !important;
  }

  .onetrust-close-btn-handler {
    width: unset !important;
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  #onetrust-button-group-parent {
    position: relative !important;
    left: unset !important;
    top: unset !important;
    transform: translateY(0) !important;
  }

  #onetrust-banner-sdk {
    bottom: 2.5rem !important;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%);
  }
}
