@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;

  &.scroll-lock {
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
  }
}

button,
textarea,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@font-face {
  font-family: 'nimbus-sans-d';
  src: url('/fonts/nimbus-sans-d-ot-regular.woff');
  font-weight: 400; //Normal
  font-display: swap;
}

@font-face {
  font-family: 'nimbus-sans-d';
  src: url('/fonts/nimbus-sans-d-ot-bold.woff');
  font-weight: 700; //Bold
  font-display: swap;
}

@font-face {
  font-family: 'nimbus-sans-extd-d';
  src: url('/fonts/nimbus-sans-d-ot-regular-extended.woff');
  font-weight: 400; //Normal
  font-display: swap;
}

@font-face {
  font-family: 'nimbus-sans-extd-d';
  src: url('/fonts/nimbus-sans-d-ot-bold-extended.woff');
  font-weight: 700; //Bold
  font-display: swap;
}

@import './cookiepro.scss';
@import './react-intl-tel-input.scss';

@layer utilities {
  .underline-thicker {
    text-decoration-thickness: 2px;
  }

  .hide-scrollbar {
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }

  @keyframes cursor-blink {
    50% {
      opacity: 0;
    }
  }
}

.search-input {
  &::-ms-clear {
    display: none !important;
    width: 0;
    height: 0;
  }
  &::-ms-reveal {
    display: none !important;
    width: 0;
    height: 0;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none !important;
  }
}

.inner-html {
  ul {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    margin: 15px 0 15px 7px;
  }
  li::before {
    content: '• ';
  }
}

// 🥔 Styling CSV imported HTML
.product-page-accordion-html {
  b,
  strong {
    font-weight: 700;
  }

  u {
    text-decoration: underline;
  }

  i {
    font-style: italic;
  }

  ul,
  ol {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 7px;
  }

  li {
    margin-bottom: 4px;

    &::before {
      content: '• ';
    }
  }
}

.tag-mask-image {
  -webkit-mask-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 24 24"><g><path d="M2 5a3 3 0 0 1 3-3h6.2a3 3 0 0 1 2 .9l8 8a3 3 0 0 1 0 4.2l-6 6.2a3 3 0 0 1-4.3 0l-8-8a3 3 0 0 1-.9-2.1V5Z"/><path d="M3 5.8C3 4.3 4.2 3 5.7 3h5.5c.7 0 1.4.3 1.9.8l7 7c1.1 1 1.1 2.7 0 3.8L14.8 20a2.7 2.7 0 0 1-3.8 0l-7-7c-.6-.5-.9-1.2-.9-1.9V5.8Z"/></g></svg>');
  mask-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 24 24"><g><path d="M2 5a3 3 0 0 1 3-3h6.2a3 3 0 0 1 2 .9l8 8a3 3 0 0 1 0 4.2l-6 6.2a3 3 0 0 1-4.3 0l-8-8a3 3 0 0 1-.9-2.1V5Z"/><path d="M3 5.8C3 4.3 4.2 3 5.7 3h5.5c.7 0 1.4.3 1.9.8l7 7c1.1 1 1.1 2.7 0 3.8L14.8 20a2.7 2.7 0 0 1-3.8 0l-7-7c-.6-.5-.9-1.2-.9-1.9V5.8Z"/></g></svg>');
}

.swiper-scrollbar-fit {
  height: 2px;
  background: #041c2c1a;
  overflow-x: hidden;

  @apply mx-[16px] mt-7 lg:mt-12;
}

.swiper-scrollbar-fit-guide {
  height: 2px;
  background: #041c2c1a;
  overflow-x: hidden;

  @apply mx-[16px] mt-7 lg:mx-[48px] lg:mt-12;
}

.swiper-scrollbar-drag {
  background: #041c2c4d;
  height: 2px;
  border-radius: 10px;
}
