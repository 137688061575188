@import 'react-intl-tel-input/dist/main.css';

.intl-tel-input {
  padding-right: 18px;
  padding-left: 18px;
}

.intl-tel-input .flag-container .selected-flag {
  width: fit-content;
  padding: 0 12px 0 18px;
  border-right: rgba(4, 28, 44, 0.1) solid 1px;
}

.intl-tel-input.allow-dropdown .flag-container {
  right: 0;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type='text'],
.intl-tel-input.allow-dropdown input[type='tel'] {
  padding: 16px 18px 16px 78px;
  margin-left: -18px;
  width: calc(100% + 36px);
}

.intl-tel-input.iti-container {
  z-index: 99994499;
}
